@font-face {
  font-family: 'Conv_DINMittelschrift-Regular';
  src: url('../fonts/DINMittelschrift-Regular.eot');
  src: local('☺'), url('../fonts/DINMittelschrift-Regular.woff') format('woff'), url('../fonts/DINMittelschrift-Regular.ttf') format('truetype'), url('../fonts/DINMittelschrift-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin font-montserrat-bold() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
@mixin font-oswald-medium() {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
}
@mixin font-oswald-light() {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}
@mixin font-helvetica-regular() {
  font-family: Arial, sans-serif;
  font-weight: 400;
}
@mixin font-helvetica-bold() {
  font-family: Arial, sans-serif;
  font-weight: 700;
}
